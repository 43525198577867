import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import visibility from 'vue-visibility-change'
import axios from 'axios'

Vue.config.productionTip = false
Vue.use(visibility)
Vue.prototype.$axios = axios

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
