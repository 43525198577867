<template>
  <v-app>
    <v-navigation-drawer app v-model="drawer" clipped width="300">
      <v-container>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title grey--text text--darken-2">
              Function Menu
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list nav dense>
          <v-list-item v-if="isPassengerMode" @click="onCollectLocation">
            <v-list-item-icon>
              <v-icon>{{collectLocationItem.icon}}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{collectLocationItem.title}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="onEditPhoneNo">
            <v-list-item-icon>
              <v-icon>mdi-phone</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>電話番号を設定する</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-container>
    </v-navigation-drawer>

    <v-app-bar :color="mainColor" dark app clipped-left>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-img src="@/assets/scsk.png" contain height="20" max-width="64" class="ma-0" />
      <v-toolbar-title class="ml-3">Limousine Location</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <v-container fluid id="map" v-visibility-change="onVisibilityChange" v-resize="onResize"></v-container>

      <v-container fluid id="btn-container" v-if="isPassengerMode" :style="btnContainerStyle">
        <v-row>
          <v-col class="pt-0 pb-1 px-4">
            <v-btn block :color="mainColor" @click="onChangeMode">{{buttonLabels[locationMode]}}</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0 pb-1 px-4">
            <v-btn block :color="mainColor" @click="onShowMessageDialog">メッセージを送信する</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0 pb-1 px-4">
            <v-btn block :color="mainColor" @click="onCallPhone">電話をかける</v-btn>
          </v-col>
        </v-row>
      </v-container>

      <v-dialog v-model="initDialog" width="280" persistent>
        <v-card>
          <v-card-text class="pa-4">
            位置情報を取得しています...
            <v-progress-circular
              indeterminate
              :size="30"
              :color="mainColor"
              class="ml-4"
            ></v-progress-circular>
          </v-card-text>
        </v-card>
      </v-dialog>

      <MessageDialog
        :value="msgDialog"
        :phoneNo="phoneNo[0]"
        @selectMessage="onMessageDialogSelect"
        @cancel="onMessageDialogCancel" />

      <PhoneDialog
        v-if="phoneDialog"
        v-model="phoneDialog"
        :phoneNo="phoneNo"
        @ok="onPhoneDialogOk" />

    </v-main>
  </v-app>
</template>

<script>
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import icon from "leaflet/dist/images/marker-icon.png"

import MessageDialog from './components/MessageDialog.vue'
import PhoneDialog from './components/PhoneDialog.vue'

const GET_LOCATION_INTERVAL = 10000
const API_BASE_URI = 'https://api.limo-loc.ccpdevelop-dev.net'
const INITIAL_LOCATION = [35.68143661944264, 139.76712479811314]
const BUTTON_LABELS = ['乗車位置を設定する', '車両位置表示に戻る']
const MESSAGE_ID = {
  RideMe: 0,
  GoThere: 1
}
const LOCATION_TITLES = ['現在地', '乗車位置']
const LOCATION_TITLE = {
  CurrentLocation: 0,
  RideLocation: 1
}
const RIDE_ICON_ANCHOR = [12, 41]
const CAR_ICON_URL = '/car.png'
const CAR_HIDE_ICON_URL = '/car_hide.png'
const CAR_ICON_ANCHOR = [25, 40]
const CAR_PARAM_REG = /car=([^&]*)/
const MODE_KEY_PARAM_REG = /mode_key=([^&]*)/
const LOCATION_MODE = {
  CarLocation: 0,
  RideLocation: 1
}
const USER_MODE = {
  PassengerMode: 0,
  AssistantMode: 1
}
const PASSENGER_MODE_KEY = 'ACu]sh7y'
const RIDE_MARKER_OFFSET = 0.005
const COLLECT_LOCATION_ITEM = [
  {icon: 'mdi-eye-off', title: '車両位置情報の収集を停止する'},
  {icon: 'mdi-eye', title: '車両位置情報の収集を開始する'}
]
const COLLECT_LOCATION_MODE = {
  On: 0,
  Off: 1
}

export default {
  components: {
    PhoneDialog,
    MessageDialog
  },

  data() {
    return {
      drawer: false,
      collectLocation: true,
      initDialog: true,
      msgDialog: false,
      phoneDialog: false,
      map: null,
      carNo: null,
      deviceCode: null,
      carMarker: null,
      rideMarker: null,
      intervalId: null,
      locationMode: LOCATION_MODE.CarLocation,
      userMode: USER_MODE.AssistantMode,
      buttonLabels: BUTTON_LABELS,
      message: '',
      phoneNo: [''],
      windowSize: {x: 0, y: 0},
      rideLocation: null,
      authToken: null,
      route: null
    }
  },

  mounted() {
    this.map = L.map('map').setView(INITIAL_LOCATION, 15)
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {}).addTo(this.map)
    this.map.on('locationfound', this.onLocationFound)
    this.map.on('locationerror', this.onLocationError)
    this.map.on('move', this.onMapMove)
    this.map.on('moveend', this.onMapMoveEnd)

    this.carMarker = L.marker(INITIAL_LOCATION, {icon: this.carIcon})

    let rideIcon = L.icon({
      iconUrl: icon,
      iconAnchor: RIDE_ICON_ANCHOR
    })
    this.rideMarker = L.marker(INITIAL_LOCATION, {icon: rideIcon})

    let found = location.search.match(CAR_PARAM_REG)
    this.carNo = found ? decodeURI(found[1]) : '1'
    found = location.search.match(MODE_KEY_PARAM_REG)
    this.userMode =
      found && decodeURI(found[1]) === PASSENGER_MODE_KEY ?
      USER_MODE.PassengerMode : USER_MODE.AssistantMode

    this.getDeviceCode()
    this.intervalId = setInterval(this.getCarLocation, GET_LOCATION_INTERVAL)
  },

  computed: {
    carIcon() {
      return L.icon({
        iconUrl: this.collectLocation ? CAR_ICON_URL: CAR_HIDE_ICON_URL,
        iconAnchor: CAR_ICON_ANCHOR
      })
    },

    btnContainerStyle() {
      let height = this.isPassengerMode ? 180 : 100
      return `top: ${this.windowSize.y - height}px`
    },

    isCarLocationMode() {
      return this.locationMode === LOCATION_MODE.CarLocation
    },

    isPassengerMode() {
      return this.userMode === USER_MODE.PassengerMode
    },

    mainColor() {
      return this.isCarLocationMode ? "primary" : "success"
    },

    collectLocationItem() {
      return COLLECT_LOCATION_ITEM[this.collectLocation ?
        COLLECT_LOCATION_MODE.On : COLLECT_LOCATION_MODE.Off]
    }
  },

  methods: {
    getDeviceCode() {
      this.$axios.get(
        `${API_BASE_URI}/limoloc/v1/device_code`,
        {
          params: {
            car_no: this.carNo
          }
        }
      )
      .then(res => {
        this.deviceCode = res.data.device_code
        this.getCarLocation()
      })
      .catch(err => {
        console.error(err)
      })
    },

    getCarLocation() {
      if (!this.deviceCode) {
        return
      }
      this.$axios.get(`${API_BASE_URI}/v1/devices/${this.deviceCode}/last_location`)
      .then(res => {
        let carLocation = [parseFloat(res.data.data.lat), parseFloat(res.data.data.lng)]
        this.carMarker.setLatLng(carLocation)
        if (this.isCarLocationMode) {
          this.map.panTo(carLocation)
        }
        if (this.initDialog) {
          this.initDialog = false
          this.carMarker.addTo(this.map)
        }
      })
      .catch(err => {
        console.error(err)
      })
    },

    getPhoneNo(carNo) {
      return new Promise((resolve, reject) => {
        this.$axios.get(
          `${API_BASE_URI}/limoloc/v1/phone_no`,
          {
            params: {
              car_no: carNo
            }
          }
        )
        .then(res => {
          resolve(res.data.phone_no)
        })
        .catch(err => {
          reject(err)
        })
      })
    },

    sendMessage(location, locationTitle) {
      this.msgDialog = false
      let params = {
        car_no: this.carNo,
        message: this.message
      }
      if (location) {
        params.location = {
          lat: location.lat,
          lng: location.lng
        }
        params.location_title = locationTitle
      }
      this.$axios.post(
          `${API_BASE_URI}/limoloc/v1/send_message`,
          params
        )
        .catch(err => {
          console.error(err)
        })
    },

    setRideMarkerTooltip(text = '') {
      let content = `乗車位置${text ? '<br/>' : ''}${text}`
      this.rideMarker.unbindTooltip()
      this.rideMarker.bindTooltip(content, {
        offset: L.point(0, -42),
        direction: 'top',
        permanent: true
      }).openTooltip()
    },

    setRoute() {
      let carLocation = this.carMarker.getLatLng()
      this.$axios.get(
        `${API_BASE_URI}/limoloc/v1/route`,
        {
          params: {
            start: `${carLocation.lat},${carLocation.lng}`,
            end: `${this.rideLocation.lat},${this.rideLocation.lng}`,
            auth_token: this.authToken
          }
        }
      )
      .then(res => {
        this.authToken = res.data.auth_token
        this.drawRoute(res.data.routes)
      })
      .catch(err => {
        console.error(err)
      })
    },

    drawRoute(routes) {
      let latlngs = []
      for (let path of routes[0].path) {
        latlngs.push([path.latitude, path.longitude])
      }
      if (this.route) {
        this.route.remove()
      }
      this.route = L.polyline(latlngs).addTo(this.map)

      let arriveTime = Math.ceil(routes[0].duration / 60)
      this.setRideMarkerTooltip(`約${arriveTime}分で到着`)
    },

    // ---------- イベントハンドラー ----------

    onVisibilityChange(evt, hidden) {
      if (hidden && this.intervalId) {
        clearInterval(this.intervalId)
        this.intervalId = null
      } else if (!this.intervalId) {
        this.getCarLocation()
        this.intervalId = setInterval(this.getCarLocation, GET_LOCATION_INTERVAL)
      }
    },

    onShowMessageDialog() {
      this.getPhoneNo(this.carNo)
      .then(
        res => {
            this.phoneNo = res.split(',')
            this.msgDialog = true
        },
        err => console.log(err)
      )
    },

    onCallPhone() {
      this.getPhoneNo(this.carNo)
      .then(
        res => window.document.location = `tel:${res.split(',')[0].replace(/-/g, '')}`,
        err => console.log(err)
      )
    },

    onChangeMode() {
      if (this.isCarLocationMode) {
        this.locationMode = LOCATION_MODE.RideLocation
        this.map.locate()
      } else {
        this.locationMode = LOCATION_MODE.CarLocation
        this.rideMarker.remove()
        this.rideLocation = null
        this.map.panTo(this.carMarker.getLatLng())
        if (this.route) {
          this.route.remove()
        }
      }
    },

    onMessageDialogSelect(message) {
      this.message = message.text
      switch (message.id) {
        case MESSAGE_ID.RideMe:
          console.log('case MESSAGE_ID.RideMe')
          if (this.rideLocation) {
            this.sendMessage(this.rideLocation, LOCATION_TITLES[LOCATION_TITLE.RideLocation])
          } else {
            this.map.locate()
          }
          break

        case MESSAGE_ID.GoThere:
          this.map.locate()
          break
      }
    },

    onMessageDialogCancel() {
      this.msgDialog = false;
    },

    onEditPhoneNo() {
      this.getPhoneNo(this.carNo)
      .then(
        res => {
          this.phoneNo = res.split(',')
          this.phoneDialog = true
        },
        err => console.log(err)
      )
    },

    onPhoneDialogOk(phoneNo) {
      this.$axios.patch(
        `${API_BASE_URI}/limoloc/v1/phone_no`,
        {
          car_no: this.carNo,
          phone_no: phoneNo.join(',')
        }
      )
      .catch(err => {
        console.error(err)
      })
    },

    onResize() {
      this.windowSize = {x: window.innerWidth, y: window.innerHeight}
    },

    onLocationFound(e) {
      if (this.msgDialog) {
        this.sendMessage(e.latlng, LOCATION_TITLES[LOCATION_TITLE.CurrentLocation])
        this.msgDialog = false
      } else {
        this.rideMarker.setLatLng(e.latlng)
        if (!this.rideLocation) {
          this.rideMarker.addTo(this.map)
          this.setRideMarkerTooltip()
        }
        this.rideLocation = e.latlng
        this.map.panTo(e.latlng)
      }
    },

    onLocationError() {
      if (this.msgDialog) {
        this.sendMessage()
        this.msgDialog = false
      } else if (!this.rideLocation) {
        let carLatLng = this.carMarker.getLatLng()
        let rideLatLng = L.latLng(carLatLng.lat, carLatLng.lng + RIDE_MARKER_OFFSET)
        this.rideMarker.setLatLng(rideLatLng)
        this.rideMarker.addTo(this.map)
        this.setRideMarkerTooltip()
        this.rideLocation = rideLatLng
        this.map.panTo(rideLatLng)
      }
    },

    onMapMove() {
      if (!this.isCarLocationMode) {
        this.rideLocation = this.map.getCenter()
        this.rideMarker.setLatLng(this.rideLocation)
      }
    },

    onMapMoveEnd() {
      if (!this.isCarLocationMode) {
        this.rideLocation = this.map.getCenter()
        this.rideMarker.setLatLng(this.rideLocation)
        // this.setRoute()
      }
    },

    onCollectLocation() {
      this.drawer = !this.drawer
      this.collectLocation = !this.collectLocation
      setTimeout(() => {
        this.carMarker.setIcon(this.carIcon)
      }, 300)
    }
  }
}
</script>

<style scoped>
#map {
  height: 100%;
  z-index: 0;
}
#btn-container {
  position: absolute;
}
</style>