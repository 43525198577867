<template>
  <v-dialog :value="value" :width="width" persistent>
    <v-card>
      <v-card-title class="py-2 grey lighten-2 subtitle-1">メッセージを送信します</v-card-title>
      <v-card-subtitle class="mt-4 mx-2">メッセージ送信先：{{phoneNo}}</v-card-subtitle>
      <v-card-text class="py-6">
        <v-row v-for="message in messages" :key="message.id">
          <v-col class="py-2">
            <v-btn block @click="onSelectMessage(message)">{{message.text}}</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-8">
            <v-btn block @click="onCancel"><v-icon left>mdi-close-thick</v-icon>Cancel</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
const MESSAGES = [
  {id: 0, text: '迎えに来てください'},
  {id: 1, text: '今からそちらに行きます'}
]

export default {
  props: {
    value: {
      type: Boolean,
      default: true
    },
    phoneNo: {
      type: String,
      required: true
    },
    width: {
      type: Number,
      default: 500
    }
  },

  data() {
    return {
      messages: MESSAGES
    }
  },

  methods: {
    onSelectMessage(message) {
      this.$emit('input', false)
      this.$emit('selectMessage', message)
    },

    onCancel() {
      this.$emit('input', false)
      this.$emit('cancel')
    }
  }
}
</script>
