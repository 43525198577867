<template>
  <v-dialog :value="value" :width="width" persistent>
    <v-card>
      <v-card-title class="py-2 grey lighten-2 subtitle-1">電話番号設定</v-card-title>
      <v-card-text>
        <v-radio-group v-model="phoneNoIdx">
          <v-container>
            <v-form ref="form">
              <v-row class="mb-1">
                <v-spacer></v-spacer>
                <v-col cols="2">
                  <v-btn @click="onAddPhoneNo" fab small>
                    <v-icon>mdi-playlist-plus</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="2">
                  <v-btn @click="onRemovePhoneNo" fab small :disabled="localPhoneNo.length <= 1">
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-for="(no, idx) in localPhoneNo" :key="idx" no-gutters align="center" class="grey lighten-5 mb-2">
                <v-col cols="1">
                  <v-radio :value="idx" class="justify-center"></v-radio>
                </v-col>
                <v-col class="my-2">
                  <v-text-field
                    v-model="localPhoneNo[idx]"
                    dense
                    clearable
                    hide-details
                    :rules="[validatePhoneNo]"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-8">
                  <v-btn block @click="onOk"><v-icon left>mdi-check-bold</v-icon>OK</v-btn>
                </v-col>
                <v-col class="pt-8">
                  <v-btn block @click="onCancel"><v-icon left>mdi-close-thick</v-icon>Cancel</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-radio-group>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'PhoneDialog',

  props: {
    value: {
      type: Boolean,
      required: true
    },
    phoneNo: {
      type: Array,
      required: true
    },
    width: {
      type: Number,
      default: 500
    }
  },

  data() {
    return {
      localPhoneNo: Array.from(this.phoneNo),
      phoneNoIdx: 0
    }
  },

  methods: {
    onAddPhoneNo() {
      this.localPhoneNo.push('')
    },

    onRemovePhoneNo() {
      if (this.localPhoneNo.length <= 1) {
        return
      }
      this.localPhoneNo.splice(this.phoneNoIdx, 1)
      if (this.phoneNoIdx >= this.localPhoneNo.length) {
        this.phoneNoIdx--
      }
    },

    validatePhoneNo(value) {
      return value.length > 0
        && /^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/.test(value.replace(/-/g, ''))
    },

    onOk() {
      if (!this.$refs.form.validate()) {
        return
      }
      this.$emit('input', false)
      this.localPhoneNo = this.localPhoneNo.splice(this.phoneNoIdx).concat(this.localPhoneNo)
      this.$emit('ok', this.localPhoneNo)
    },

    onCancel() {
      this.$emit('input', false)
      this.$emit('cancel')
    },
  }
}
</script>

<style scoped>
</style>
